import { Field, Table } from "./Constants"

export function isEndGame(squares) {
    const include = squares && squares.some(row => row.includes(null))
    return !include
}

export function evaluateMove(squares, ai) {
    let score = 0
    let temp = 0
    let position = { row: null, column: null }
    let bestPositions = []
    for (let row = 0; row < Table.NUMBER_OF_ROWS; row++)
    {
        for (let column = 0; column < Table.NUMBER_OF_COLUMNS; column++)
        {
            temp = scoreEvaluation(squares, row, column, ai);
            if (temp > score)
            {
                score = temp;
                position.row = row;
                position.column = column;
                bestPositions = []
                bestPositions.push({ score: score, row: row, column: column })
            } else if (temp === score) {
                bestPositions.push({ score: score, row: row, column: column })
            }
        }
    }
    var item = bestPositions[Math.floor(Math.random()*bestPositions.length)]

    position.row = item.row
    position.column = item.column

    return position;
}

function scoreEvaluation(squares, x, y, ai) {
            let valueS = 0;                         
            let valueN = 0;
            let valueNE = 0;
            let valueSE = 0;
            let valueNW = 0;
            let valueSW = 0;
            let valueE = 0;
            let valueW = 0;

            let scoreOwn = [ 2, 4, 6, 100 ];
            let scoreOther = [ 1, 3, 5, 51 ];

            let player;
            if (ai === Field.Player1)
            {
                player = Field.Player2;
            }
            else
            {
                player = Field.Player1;
            }

            if (squares[x][y] === Field.Empty)
            {
                try
                {
                    //North                                         
                    if (squares[x][y - 1] === player)
                    {
                        valueN = scoreOther[0];
                        if (squares[x][y - 2] === player)
                        {
                            valueN = scoreOther[1];
                            if (squares[x][y - 3] === player)
                            {
                                valueN = scoreOther[2];
                                if (squares[x][y - 4] === player)
                                {
                                    valueN = scoreOther[3];
                                }
                            }
                        }
                    }
                    else if (squares[x][y - 1] === ai)
                    {
                        valueN = scoreOwn[0];
                        if (squares[x][y - 2] === ai)
                        {
                            valueN = scoreOwn[1];
                            if (squares[x][y - 3] === ai)
                            {
                                valueN = scoreOwn[2];
                                if (squares[x][y - 4] === ai)
                                {
                                    valueN = scoreOwn[3];
                                }
                            }
                        }
                    }

                }
                catch (e)
                {

                }
                //South
                try
                {
                    if (squares[x][y + 1] === player)
                    {
                        valueS = scoreOther[0];
                        if (squares[x][y + 2] === player)
                        {
                            valueS = scoreOther[1];
                            if (squares[x][y + 3] === player)
                            {
                                valueS = scoreOther[2];
                                if (squares[x][y + 4] === player)
                                {
                                    valueS = scoreOther[3];
                                }
                            }
                        }
                    }
                    else if (squares[x][y + 1] === ai)
                    {
                        valueS = scoreOwn[0];
                        if (squares[x][y + 2] === ai)
                        {
                            valueS = scoreOwn[1];
                            if (squares[x][y + 3] === ai)
                            {
                                valueS = scoreOwn[2];
                                if (squares[x][y + 4] === ai)
                                {
                                    valueS = scoreOwn[3];
                                }
                            }
                        }
                    }
                }
                catch (e)
                {
                }



                try
                {
                    //West
                    if (squares[x - 1][y] === player)
                    {
                        valueW = scoreOther[0];
                        if (squares[x - 2][y] === player)
                        {
                            valueW = scoreOther[1];
                            if (squares[x - 3][y] === player)
                            {
                                valueW = scoreOther[2];
                                if (squares[x - 4][y] === player)
                                {
                                    valueW = scoreOther[3];
                                }
                            }
                        }
                    }
                    else if (squares[x - 1][y] === ai)
                    {
                        valueW = scoreOwn[0];
                        if (squares[x - 2][y] === ai)
                        {
                            valueW = scoreOwn[1];
                            if (squares[x - 3][y] === ai)
                            {
                                valueW = scoreOwn[2];
                                if (squares[x - 4][y] === ai)
                                {
                                    valueW = scoreOwn[3];
                                }
                            }
                        }
                    }
                }
                catch (e)
                {
                }

                //East
                try
                {
                    if (squares[x + 1][y] === player)
                    {
                        valueE = scoreOther[0];
                        if (squares[x + 2][y] === player)
                        {
                            valueE = scoreOther[1];
                            if (squares[x + 3][y] === player)
                            {
                                valueE = scoreOther[2];
                                if (squares[x + 4][y] === player)
                                {
                                    valueE = scoreOther[3];
                                }
                            }
                        }
                    }
                    else if (squares[x + 1][y] === ai)
                    {
                        valueE = scoreOwn[0];
                        if (squares[x + 2][y] === ai)
                        {
                            valueE = scoreOwn[1];
                            if (squares[x + 3][y] === ai)
                            {
                                valueE = scoreOwn[2];
                                if (squares[x + 4][y] === ai)
                                {
                                    valueE = scoreOwn[3];
                                }
                            }
                        }
                    }
                }
                catch (e)
                {
                }

                //NW
                try
                {
                    if (squares[x - 1][y - 1] === player)
                    {
                        valueNW = scoreOther[0];
                        if (squares[x - 2][y - 2] === player)
                        {
                            valueNW = scoreOther[1];
                            if (squares[x - 3][y - 3] === player)
                            {
                                valueNW = scoreOther[2];
                                if (squares[x - 4][y - 4] === player)
                                {
                                    valueNW = scoreOther[3];
                                }
                            }
                        }
                    }
                    else if (squares[x - 1][y - 1] === ai)
                    {
                        valueNW = scoreOwn[0];
                        if (squares[x - 2][y - 2] === ai)
                        {
                            valueNW = scoreOwn[1];
                            if (squares[x - 3][y - 3] === ai)
                            {
                                valueNW = scoreOwn[2];
                                if (squares[x - 4][y - 4] === ai)
                                {
                                    valueNW = scoreOwn[3];
                                }
                            }
                        }
                    }
                }
                catch (e)
                {
                }

                //SE
                try
                {
                    if (squares[x + 1][y + 1] === player)
                    {
                        valueSE = scoreOther[0];
                        if (squares[x + 2][y + 2] === player)
                        {
                            valueSE = scoreOther[1];
                            if (squares[x + 3][y + 3] === player)
                            {
                                valueSE = scoreOther[2];
                                if (squares[x + 4][y + 4] === player)
                                {
                                    valueSE = scoreOther[3];
                                }
                            }
                        }
                    }
                    else if (squares[x + 1][y + 1] === ai)
                    {
                        valueSE = scoreOwn[0];
                        if (squares[x + 2][y + 2] === ai)
                        {
                            valueSE = scoreOwn[1];
                            if (squares[x + 3][y + 3] === ai)
                            {
                                valueSE = scoreOwn[2];
                                if (squares[x + 4][y + 4] === ai)
                                {
                                    valueSE = scoreOwn[3];
                                }
                            }
                        }

                    }
                }
                catch (e)
                {
                }

                //NE
                try
                {
                    if (squares[x + 1][y - 1] === player)
                    {
                        valueNE = scoreOther[0];
                        if (squares[x + 2][y - 2] === player)
                        {
                            valueNE = scoreOther[1];
                            if (squares[x + 3][y - 3] === player)
                            {
                                valueNE = scoreOther[2];
                                if (squares[x + 4][y - 4] === player)
                                {
                                    valueNE = scoreOther[3];
                                }
                            }
                        }
                    }
                    else if (squares[x + 1][y - 1] === ai)
                    {
                        valueNE = scoreOwn[0];
                        if (squares[x + 2][y - 2] === ai)
                        {
                            valueNE = scoreOwn[1];
                            if (squares[x + 3][y - 3] === ai)
                            {
                                valueNE = scoreOwn[2];
                                if (squares[x + 4][y - 4] === ai)
                                {
                                    valueNE = scoreOwn[3];
                                }
                            }
                        }
                    }
                }
                catch (e)
                {
                }

                //SW
                try
                {
                    if (squares[x - 1][y + 1] === player)
                    {
                        valueSW = scoreOther[0];
                        if (squares[x - 2][y + 2] === player)
                        {
                            valueSW = scoreOther[1];
                            if (squares[x - 3][y + 3] === player)
                            {
                                valueSW = scoreOther[2];
                                if (squares[x - 4][y + 4] === player)
                                {
                                    valueSW = scoreOther[3];
                                }
                            }
                        }
                    }
                    else if (squares[x - 1][y + 1] === ai)
                    {
                        valueSW = scoreOwn[0];
                        if (squares[x - 2][y + 2] === ai)
                        {
                            valueSW = scoreOwn[1];
                            if (squares[x - 3][y + 3] === ai)
                            {
                                valueSW = scoreOwn[2];
                                if (squares[x - 4][y + 4] === ai)
                                {
                                    valueSW = scoreOwn[3];
                                }
                            }
                        }
                    }
                }
                catch (e)
                {
                }

            }
        

            if ((valueN === scoreOwn[2] && valueS === scoreOwn[0]) || (valueN === scoreOwn[1] && valueS === scoreOwn[1])
                || (valueN === scoreOwn[0] && valueS === scoreOwn[2]))
            {
                return scoreOwn[3];
            }

            if ((valueE === scoreOwn[2] && valueW === scoreOwn[0]) || (valueE === scoreOwn[1] && valueW === scoreOwn[1])
                || (valueE === scoreOwn[0] && valueW === scoreOwn[2]))
            {
                return scoreOwn[3];
            }

            if ((valueNE === scoreOwn[2] && valueSW === scoreOwn[0]) || (valueNE === scoreOwn[1] && valueSW === scoreOwn[1])
                || (valueNE === scoreOwn[0] && valueSW === scoreOwn[2]))
            {
                return scoreOwn[3];
            }

            if ((valueSE === scoreOwn[2] && valueNW === scoreOwn[0]) || (valueSE === scoreOwn[1] && valueNW === scoreOwn[1])
                || (valueSE === scoreOwn[0] && valueNW === scoreOwn[2]))
            {
                return scoreOwn[3];
            }

            if ((valueN === scoreOther[2] && valueS === scoreOther[0]) || (valueN === scoreOther[1] && valueS === scoreOther[1])
                || (valueN === scoreOther[0] && valueS === scoreOther[2]))
            {
                return scoreOther[3];
            }

            if ((valueE === scoreOther[2] && valueW === scoreOther[0]) || (valueE === scoreOther[1] && valueW === scoreOther[1])
                || (valueE === scoreOther[0] && valueW === scoreOther[2]))
            {
                return scoreOther[3];
            }
 
            if ((valueNE === scoreOther[2] && valueSW === scoreOther[0]) || (valueNE === scoreOther[1] && valueSW === scoreOther[1])
                || (valueNE === scoreOther[0] && valueSW === scoreOther[2]))
            {
                return scoreOther[3];
            }

            if ((valueSE === scoreOther[2] && valueNW === scoreOther[0]) || (valueSE === scoreOther[1] && valueNW === scoreOther[1])
                || (valueSE === scoreOther[0] && valueNW === scoreOther[2]))
            {
                return scoreOther[3];
            }


            

            let s = Math.max(valueS, Math.max(valueN, Math.max(valueSE, Math.max(valueSW,
                Math.max(valueNE, Math.max(valueNW, Math.max(valueE, valueW)))))));

                let p = 0;
                let p0 = 0;
                let p1 = 0;
                let p2 = 0;
                let p3 = 0;
                let p4 = 0;
                let p5 = 0;
                let p6 = 0;
                let p7 = 0;
                let p8 = 0;

            if ((valueN === scoreOwn[1] && valueS === scoreOwn[0]) || (valueN === scoreOwn[0] && valueS === scoreOwn[1]))
            {
                p1 = scoreOwn[2];
            }else if ((valueN === scoreOther[1] && valueS === scoreOther[0]) || (valueN === scoreOther[0] && valueS === scoreOther[1]))
            {
                p1 = scoreOther[2];
            }

            if ((valueE === scoreOwn[1] && valueW === scoreOwn[0]) || (valueE === scoreOwn[0] && valueW === scoreOwn[1]))
            {
                p2 = scoreOwn[2];
            }
            else if ((valueE === scoreOther[1] && valueW === scoreOther[0]) || (valueE === scoreOther[0] && valueW === scoreOther[1]))
            {
                p2 = scoreOther[2];
            }

            if ((valueNE === scoreOwn[1] && valueSW === scoreOwn[0]) || (valueNE === scoreOwn[0] && valueSW === scoreOwn[1]))
            {
                p3 = scoreOwn[2];
            }
            else if ((valueNE === scoreOther[1] && valueSW === scoreOther[0]) || (valueNE === scoreOther[0] && valueSW === scoreOther[1]))
            {
                p3 = scoreOther[2];
            }

            if ((valueSE === scoreOwn[1] && valueNW === scoreOwn[0]) || (valueSE === scoreOwn[0] && valueNW === scoreOwn[1]))
            {
                p4 = scoreOwn[2];
            }
            else if ((valueSE === scoreOther[1] && valueNW === scoreOther[0]) || (valueSE === scoreOther[0] && valueNW === scoreOther[1]))
            {
                p4 = scoreOther[2];
            }


            if (valueN === scoreOwn[0] && valueS === scoreOwn[0])
            {
                p5 = scoreOwn[1];
            }
            else if (valueN === scoreOther[0] && valueS === scoreOther[0])
            {
                p5 = scoreOther[1];
            }

            if (valueE === scoreOwn[0] && valueW === scoreOwn[0])
            {
                p6 = scoreOwn[1];
            }
            else if (valueE === scoreOther[0] && valueW === scoreOther[0])
            {
                p6 = scoreOther[1];
            }
            if (valueNE === scoreOwn[0] && valueSW === scoreOwn[0])
            {
                p7 = scoreOwn[1];
            }
            else if (valueNE === scoreOther[0] && valueSW === scoreOther[0])
            {
                p7 = scoreOther[1];
            }
            if (valueSE === scoreOwn[0] && valueNW === scoreOwn[0])
            {
                p8 = scoreOwn[1];
            }
            else if (valueSE === scoreOther[0] && valueNW === scoreOther[0])
            {
                p8 = scoreOther[1];
            }
            p0 = Math.max(p5, Math.max(p6, Math.max(p7, p8)));

            p = Math.max(p1, Math.max(p2, Math.max(p3, p4)));

            p = Math.max(p, p0);

            if (p >= s)
            {
                return p;
            }
            else
            {
                return s;
            }
}