import { Field, Table } from "./Constants";


// function checkField(field, playerOneCount, playerTwoCount) {
// 	switch (field) {
// 		case Field.Player1:
// 			playerOneCount += 1;
// 			playerTwoCount = 0;
// 			break;
// 		case Field.Player2:
// 			playerOneCount = 0;
// 			playerTwoCount += 1;
// 			break;
// 		default:
// 			playerOneCount = 0;
// 			playerTwoCount = 0;
// 			break;
// 	}
// }

export function checkWin(squares) {

	if (!squares) {
		return
	}
        
    let playerOneCount = 0
  	let playerTwoCount = 0

  	let i, z, h, l;

            //North to South
  	for (z = 0; z < Table.NUMBER_OF_COLUMNS; z++) {
		playerOneCount = 0;
		playerTwoCount = 0;
		for (i = 0; i < Table.NUMBER_OF_ROWS; i++) {	
			switch (squares[i][z]) {
				case Field.Player1:
					playerOneCount += 1;
					playerTwoCount = 0;
					break;
				case Field.Player2:
					playerOneCount = 0;
					playerTwoCount += 1;
					break;
				default:
					playerOneCount = 0;
					playerTwoCount = 0;
					break;
			}

			if (playerOneCount === Table.NUMBER_TO_WIN) {
				return Field.Player1;
			}
			if (playerTwoCount === Table.NUMBER_TO_WIN) {
				return Field.Player2
			}
		}
    }

	//West to East
	for (z = 0; z < Table.NUMBER_OF_ROWS; z++) {
		playerOneCount = 0;
		playerTwoCount = 0;
		for (i = 0; i < Table.NUMBER_OF_COLUMNS; i++) {
			switch (squares[z][i]) {
				case Field.Player1:
					playerOneCount += 1;
					playerTwoCount = 0;
					break;
				case Field.Player2:
					playerOneCount = 0;
					playerTwoCount += 1;
					break;
				default:
					playerOneCount = 0;
					playerTwoCount = 0;
					break;
			}

			if (playerOneCount === Table.NUMBER_TO_WIN) {
				return Field.Player1;
			}
			if (playerTwoCount === Table.NUMBER_TO_WIN) {
				return Field.Player2
			}
		}
	}

	//NW to SE
	for (h = 0; h <= Table.NUMBER_OF_ROWS - Table.NUMBER_TO_WIN; h++) {
		for (l = 0; l <= Table.NUMBER_OF_COLUMNS - Table.NUMBER_TO_WIN; l++) {
			playerOneCount = 0;
			playerTwoCount = 0;
			for (z = h, i = l; z < h + Table.NUMBER_TO_WIN && i < l + Table.NUMBER_TO_WIN; i++, z++) {
				switch (squares[z][i]) {
					case Field.Player1:
						playerOneCount += 1;
						playerTwoCount = 0;
						break;
					case Field.Player2:
						playerOneCount = 0;
						playerTwoCount += 1;
						break;
					default:
						playerOneCount = 0;
						playerTwoCount = 0;
						break;
				}
	
				if (playerOneCount === Table.NUMBER_TO_WIN) {
					return Field.Player1;
				}
				if (playerTwoCount === Table.NUMBER_TO_WIN) {
					return Field.Player2
				}
			}
		}
	}

	//SW to NE
	for (h = 0; h <= Table.NUMBER_OF_ROWS - Table.NUMBER_TO_WIN; h++) {
		for (l = 0; l <= Table.NUMBER_OF_COLUMNS - Table.NUMBER_TO_WIN; l++) {
			playerOneCount = 0;
			playerTwoCount = 0;
			for (z = h, i = l + Table.NUMBER_TO_WIN -1; z < h + Table.NUMBER_TO_WIN && i > l - Table.NUMBER_TO_WIN; i--, z++) {
				switch (squares[z][i]) {
					case Field.Player1:
						playerOneCount += 1;
						playerTwoCount = 0;
						break;
					case Field.Player2:
						playerOneCount = 0;
						playerTwoCount += 1;
						break;
					default:
						playerOneCount = 0;
						playerTwoCount = 0;
						break;
				}
	
				if (playerOneCount === Table.NUMBER_TO_WIN) {
					return Field.Player1;
				}
				if (playerTwoCount === Table.NUMBER_TO_WIN) {
					return Field.Player2
				}
			}
		}
		

	}
	
	return false;
        
  }
