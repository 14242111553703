export const Player = Object.freeze({
    USER: 0,
    CPU: 1,
})

export const Field = Object.freeze({
    Player1: 'X',
    Player2: 'O',
    Empty: null,
})

export const Table = Object.freeze({
    NUMBER_OF_ROWS: 10,
    NUMBER_OF_COLUMNS: 10,
    NUMBER_TO_WIN: 5,
})

