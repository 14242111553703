import { Button } from "@mui/material"
import React from "react"
import { checkWin } from "../util/CalculateWinner"
import { Field, Table } from "../util/Constants"
import { evaluateMove } from "../util/Helper"
import Board from "./Board"

class Game extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            xIsNext: true,
        }
    }

    componentDidMount() {
        this.initializeSquares()
    }

    initializeSquares = () => {
        let squares = new Array(Table.NUMBER_OF_ROWS)
        for (let i = 0; i< squares.length; i++) {
            squares[i] = new Array(Table.NUMBER_OF_COLUMNS).fill(null)
        }
        this.setState({ squares })
    }

    handleClick = (i, j) => {
        const squares = this.state.squares.slice();
        if (checkWin(squares) || squares[i][j]) {
            return
        }
        squares[i][j] = this.state.xIsNext ? Field.Player1 : Field.Player2

        this.setState({
            squares,
            xIsNext: !this.state.xIsNext
        }, () => {
            if (!this.state.xIsNext) {
                const position = evaluateMove(squares, Field.Player2)
                this.handleClick(position.row, position.column)
            }
        })
    }

    newGameClick = () => {
        this.initializeSquares()
        this.setState({ xIsNext: true })
    }


    render() {
        const { squares, xIsNext } = this.state
        return (
            <div>
                <Button 
                    variant="contained"
                    onClick={this.newGameClick}>
                        New Game
                </Button>
                <div className="game">
                
                    <div className="game-board">
                        <Board
                            squares={squares}
                            xIsNext={xIsNext}
                            handleClick={this.handleClick}
                        />
                    </div>
                    {/* <div className="game-info">
                        <div>status</div>
                    </div> */}
                </div>
            </div>
           
        )
    }
}

export default Game